import React, { useState, useEffect } from "react"
import axios from "axios";
import { StaticImage } from "gatsby-plugin-image";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo"
import { Trans } from 'gatsby-plugin-react-i18next';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import "./detail.scss"
import TitleLogo from "../components/title-logo/title-logo";
import { useI18next } from 'gatsby-plugin-react-i18next';


export default function ProductDetail({ data }) {
    const { language, languages, changeLanguage } = useI18next();
    const post = data.restApiApiProducts;
    const [coverImg, setCoverImg] = useState(post.cover);
    const [category, setCategory] = useState([]);
    const [product, setProduct] = useState([]);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const setCoverImgWithIndex = (imagePath, index) => {
        setCoverImg(imagePath);
        setSelectedImageIndex(index);
    };

    const getProducts = () => {

        axios.get(`https://cmsv2.dact.studio/api/product/${post.endpointId}/${language}`).then(
            (response) => {
                setProduct(response.data);
            }
        );
    };
    useEffect(() => {
        getProducts();
    }, []);

    useEffect(() => {
        if (localStorage.getItem('categories') != null) {
            setCategory(JSON.parse(localStorage.getItem('categories')));
        }
        axios.get(`https://cmsv2.dact.studio/api/categories/${language}`).then((response) => {
            setCategory(response.data);
            localStorage.setItem('categories', JSON.stringify(response.data));
        });

    }, []);




    return (
        <Layout>
            {product.map((item, index) =>
                <Seo title={item.name} />
            )}
            <section>
                <section className="title-logo" id="title-logo">
                    {product.map((item, index) =>
                        <TitleLogo siteTitle={item.name} siteComment={<span><a href="/" ><Trans>home</Trans></a> / <a href="/products" ><Trans>products</Trans> </a>/ <a href="#" >{item.name} </a></span>} />,

                    )}
                </section>
                <section className="productDetail-page bg-white" id="products">
                    <div className="container" id="slide-button">
                        <div className="row">
                            <div className="col-lg-6 mobile-hidden">
                                <div className="row">
                                    <div className="mob"></div>
                                    <img className="img-fluid" src={coverImg} alt={post.name} />
                                    {post.images.map((image, index) => (
                                        <a
                                            key={index}
                                            className={`col-lg-2 mt-3 img-border me-2 ${selectedImageIndex === index ? "selected" : ""
                                                }`}
                                        >
                                            <img
                                                onClick={() => setCoverImgWithIndex(image.path, index)}
                                                className="img-fluid"
                                                src={image.path}
                                                alt={post.name}
                                            />
                                        </a>
                                    ))}

                                </div>
                            </div>
                            <div className="mobile-visible d-none">
                                <div id="testimonials" class="carousel slide" data-bs-ride="carousel" data-bs-keyboard="true">
                                    <div class="carousel-inner">
                                        {post.images.map((image, index) =>
                                            <div class="carousel-item active">
                                                <img className="img-fluid" src={image.path} alt={index} />
                                            </div>
                                        )}
                                    </div>

                                    <button class=
                                        "carousel-control-prev" type="button" data-bs-target="#testimonials" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon text-black" aria-hidden="true"></span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#testimonials" data-bs-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="title mt-2">
                                    {product.map((item, index) =>
                                        <p className="questrial-25">{item.name}</p>
                                    )}
                                </div>
                                <div className="features">
                                    <a>
                                        <ul>
                                            {product.map((item, index) =>

                                                <Link to={'/categories/' + item.category.slug}>
                                                    {/* <li>{post.code}</li> */}
                                                    <li>{item.category.name}
                                                    </li>
                                                </Link>
                                            )}
                                        </ul>
                                    </a>
                                    <Tabs defaultActiveKey="desctription" className="mb-3">
                                        <Tab eventKey="desctription" title="Açıklama">
                                            {product.map((item, index) =>

                                                <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                            )}
                                        </Tab>
                                        <Tab eventKey="profile" title="Kullanma Kılavuzu">
                                            {post.documents.map((document, index) =>
                                                <Link key={index} to={document.path} target="_blank">
                                                    <i class="fa fa-file-pdf fa-3x m-3 text-warning"></i>

                                                </Link>
                                            )}
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-5 mb-5">
                                <p className="questrial-20 mb-5">
                                    {product.map((item, index) =>

                                        <div className="detail-table" dangerouslySetInnerHTML={{ __html: item.detail }} />
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pb-5">

                        {product.map((prdc, j) =>
                            category.map((item, i) =>
                                <>
                                    <div class="row justify-content-evenly">
                                        {item.slug == prdc.category.slug && item.product.map((product, k) =>
                                            <>
                                                {item.product.length > 1 && k == 0 && <h5 className="questrial-40 text-center mb-5"><Trans>similar products</Trans></h5>}
                                                {product.slug !== prdc.slug &&
                                                    <div className="col-lg-4 col-6 ">
                                                        <Link to={'/product-detail/' + product.slug}>
                                                            <div className="card card-1 pb-10 border" >
                                                                <div className="card-title text-center pb-0">
                                                                    <h5 >{product.name}</h5>
                                                                </div>
                                                                <div className="d-flex justify-content-center">
                                                                    <img style={{ width: "300px" }} src={product.cover + '?2'} alt={product.name} />
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                }
                                            </>
                                        )}
                                    </div>
                                </>
                            )
                        )}
                    </div>

                </section>

            </section>
        </Layout>
    )
}

export const query = graphql`
query ($language: String!, $slug: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    restApiApiProducts(slug: {eq: $slug}){
        name,
        description,
        code,
        slug,
        cover,
        detail,
        endpointId,
        
        images{
            name,
            path
        },
        documents{
            name,
            path
        },
        category{
            name,
            slug
        },
        attachments {
            name,
            path
          }
    } 
   
  }
`